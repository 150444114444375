html {
  margin: 0;
}

body {
  display: grid;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page__header {
  background-color: #294189;
  text-align: center;
}

.page__title {
  color: white;
}

.navigation_header {
  text-align: left;
  background-color: #ffffff;
}

.nav_links__list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
}

.nav_links__item {
  display: inline-block;
  margin: 0 24px 0 0;
}

.nav_links__item:first-child {
  padding-left: 0;
}
.nav_links__item:last-child {
  padding-right: 0;
  margin-left: auto;
  margin-right: 0;
}

.nav_links__link {
  color: #191919;
  display: inline-block;
  padding: 8px 0 4px;
  border-bottom: 4px solid transparent;
  text-decoration: unset;
}

.nav_links__link:hover,
.nav_links__link:focus {
  text-decoration: underline;
}

.nav_links__link.active {
  border-bottom-color: #294089;
}

.page__content {
  display: grid;
  width: 1284px;
  justify-content: center;
  margin: 0 auto;
  gap: 24px;
  grid-template-columns: repeat(12, 85px);
}

.page__background {
  padding: 24px 0;
  background-color: #f3f3f3;
  min-height: 100vh; /* TODO: Take up rest of screen height */
}

.sidebar_metrics__container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  align-items: baseline;
}

.sidebar_metrics__container:last-child {
  margin-bottom: 0;
}

.sidebar_metrics__heading {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 22px;
  line-height: 1.1363636364;
  margin-bottom: 10px;
}

.sidebar_metrics__value {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 28px;
  line-height: 1.1785714286;
  margin-right: 16px;
}

.sidebar_metrics__label {
  font-family: "Roboto";
  font-weight: 300;
  font-size: 16px;
  line-height: 1.375;
}

.main_metrics__label {
  font-family: "Roboto";
  font-weight: 300;
  color: #191919;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 28px;
}

#overall_pounds_caught {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#catch_dashboard > :nth-child(2) {
  display: grid;
  row-gap: 14px;
  column-gap: 24px;
  grid-template-columns: repeat(9, 1fr);
}

#catch_dashboard .VictoryContainer {
  grid-column: 1 / 3;
}
.ytd_reported_catches__component {
  grid-column: 4 / span 9;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: 40px repeat(1, 1fr);
  grid-column-gap: 20px;
}

.ytd_reported_catches_bar_graph__container {
  grid-column: 4 / span 6;
  grid-row: 1;
  margin-top: 1rem;
  padding: 0.5rem;
  font-size: 14px;
  display: grid;
  grid-template-rows: repeat(3, 2rem);
  grid-template-columns: repeat(2, 1fr);
}

.ytd_reported_catches_bar_graph_bar_graph__left_label {
  grid-row: 3;
}

.ytd_reported_catches_bar_graph_bar_graph__right_label {
  grid-row: 3;
  text-align: right;
}

dl {
  border: 1px solid #d8d8d8;
  display: grid;
  grid-template-columns: auto auto;
  gap: 0 16px;
  margin: 0 auto;

  font-size: 16px;
  line-height: 1.875;
  font-weight: 300;
}

dt {
  text-align: right;
}

dd {
  text-align: left;
  margin-left: 0;
}
